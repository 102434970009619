import React from 'react'; 
import { useDispatch } from 'react-redux';

import { RemoveTicket } from '../../redux/actions/ticketActions';

import trashIcon from '../../trash.svg'

const ReservationFlight = props => {
  const dispatch = useDispatch();  

  const deleteFlight = (id) => {  
      dispatch(RemoveTicket(id));         
  }

  return (
    <React.Fragment>
        <div className="flight-item">
            <div className="data-flight">
                <div className="location from">
                    <div className="time">5:50 <span>am</span></div>
                    <div className="place">{props.ticket.attributes.city_origin}</div>
                </div>
                <div className="location to">
                    <div className="time">7:25 <span>am</span></div>
                    <div className="place">{props.ticket.attributes.city_destination}</div>
                </div>
            </div>
            <div className="data-amount">
            {props.ticket.attributes.number} <span>Tickets</span>
            </div>
            <div className="data-prices">
                <div className="price">
                    ${props.ticket.attributes.cost*props.ticket.attributes.number}.00 MXN
                    <span>x{props.ticket.attributes.number} ${props.ticket.attributes.cost}.00</span>
                </div>
            </div>
            <div className="data-cancel">
                <button onClick={() => deleteFlight(props.ticket.id)}><img src={trashIcon}></img></button>
            </div>                        
        </div>   
    </React.Fragment>
  );
};

export default ReservationFlight;
