import React, {useState} from 'react'; 
import { useDispatch, useSelector } from 'react-redux';

import ReservationFlight from '../components/reservationFlight/reservationFlight';

import { ClearTickets } from '../redux/actions/ticketActions';

const Cart = props => {
  const store = useSelector(state => state);
  const [successReservation, setSuccessReservation] = useState(false);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();    

  const makeReservation = () => {
      
    dispatch(ClearTickets());  
    launchSuccessMsg(); 
  } 

  const launchSuccessMsg = () => {
    setSuccessReservation(true);
    setTimeout(() => { setSuccessReservation(false); }, 5000)   
  }
  
  return (
    <React.Fragment>
    <div className='cart'>      
        <h2>Mis reservas</h2>  
        <div className="cart-container">
            <div className="bookings">
                
                {(store.tickets.tickets.length)?  
                <React.Fragment>
                <div className="booking-list">                
                    {store.tickets.tickets.map((ticket) =>
                        <ReservationFlight key={ticket.id} ticket={ticket}/>
                    )}
                </div>                              
                </React.Fragment>
                : 
                <p className="no-data">No tienes ningún boleto reservado.</p>
                }

            </div>
            <div className="summary">
                <h3>Reservar tickets</h3>
                <hr/>
                <p>Pellentesque vulputate ultricies mi, molestie sollicitudin sapien pulvinar et. Vestibulum volutpat sed nunc venenatis dapibus.</p>
                {(store.tickets.tickets.length)?  
                <React.Fragment>
                <form className="form-reservation">                        
                    <div className="name-info">
                        <div className="field-cont">
                            <label htmlFor="Nombre">Nombre</label>
                            <input className="field" id="firstName" name="firstName" placeholder="" />
                        </div>                               
                        <div className="field-cont">
                            <label htmlFor="lastName">Apellidos</label>
                            <input className="field" id="lastName" name="lastName" placeholder="" />
                        </div>
                    </div>
                    <div className="address-info">
                        <div className="field-cont">
                            <label htmlFor="address">Dirección</label>
                            <input className="field" id="address" name="address" placeholder="" />
                        </div>
                    </div>                            
                    <div className="mail-info">
                        <div className="field-cont">
                            <label htmlFor="email">Email</label>
                            <input className="field" id="email" name="email" placeholder="" type="email"/>
                        </div>
                    </div>                        
                </form> 
                <a  className="reservation" onClick={makeReservation}>¡Reservar!</a>
                </React.Fragment>
                : null}
            </div>
        </div>
    </div>    
    {(successReservation)?<div className="sucess-alert">¡Su reservación se ha generado exitosmente!</div>:null}
    </React.Fragment>
  );
};

export default Cart;
