import { combineReducers } from 'redux';
import { ticketReducer } from './ticketReducer';
import { citiesReducer } from './citiesReducer';
import { flightsReducer } from './flightsReducer';

const reducers = combineReducers({
    allCities : citiesReducer,
    flights: flightsReducer,
    tickets: ticketReducer
})

export default reducers;