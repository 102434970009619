import React, {useEffect, useState} from 'react';
import { BrowserRouter, Switch, Route, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Parse from 'parse/dist/parse.min.js';
import { ParseConfig } from './config/parseconf';

import { getCities } from './redux/actions/citiesAction';

import Logo from './logo.svg'
import CartIcon from './cart.svg'
import './styles/App.scss'

import Home from './pages/Home';
import Cart from './pages/Cart';

Parse.initialize(ParseConfig.PARSE_APPLICATION_ID,ParseConfig.PARSE_JAVASCRIPT_KEY);
Parse.serverURL = ParseConfig.PARSE_HOST_URL;

function App() {
  const store = useSelector(state => state);
  const tickets = 0;  
  const [loading, setLoading] = useState(false);  
  const dispatch = useDispatch();

  const getParseCities = async () => {
    const parseQuery = new Parse.Query('city');
    const queryResults = await parseQuery.find();
    if(queryResults){
      dispatch(getCities(queryResults));      
      setLoading(true);      
    }        
  }

  useEffect(() =>{
    getParseCities();
  },[])

  return (
    <React.Fragment>
      {(loading)?
      <div className="layout">
      <BrowserRouter>
        <div className="header">
          <div className="header-container">
            <div className="logo-container">
              <NavLink to="/">
                <img src={Logo} width="50" alt="TrueFlights Logo" /> TrueFlight
              </NavLink>
            </div>
            <div className="my-cart">
              <NavLink to="/cart">
                <img alt="Cart" src={CartIcon}/>
                <span>{store.tickets.tickets.length}</span>   
              </NavLink>     
            </div>
          </div>          
        </div>

        <div className="container">          
            <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/cart' component={Cart} />  
            </Switch>                  
        </div>
        </BrowserRouter>  
      </div>   
   :<div><h1>Cargando</h1></div> }   
    </React.Fragment>
  );
}

export default App;
