import React from 'react'; 
import { useDispatch } from 'react-redux';
import { AddTicket } from '../../redux/actions/ticketActions';
import { ClearFlights } from '../../redux/actions/flightActions';
import moment from 'moment';

const CardFlight = props => {
  const dispatch = useDispatch();  

  const pickFlight = (flight,ticketNumber, ticketDate) => {        
      var dataFlight = {...flight, attributes:{...flight.attributes, number: ticketNumber, date:ticketDate}};      
      console.log(dataFlight);
      dispatch(AddTicket(dataFlight));
      dispatch(ClearFlights());
      props.successMsg();         
  }

  return (
    <React.Fragment>
        <div className="card-flight">
            <div className="itinerary">
                <div className="location from">
                <div className="time">{moment(props.flight.attributes.date).format("HH:mm A")}</div>
                <div className="place">{props.flight.attributes.city_origin}</div>
                </div>
                <div className="location to">
                <div className="time">{moment(props.flight.attributes.date).add(props.flight.attributes.time_flight, 'm').format("HH:mm A")}</div>
                <div className="place">{props.flight.attributes.city_destination}</div>
                </div>
            </div>
            <div className="recomendations">
                <h4>Recomendaciones</h4>
                <p>Pellentesque vulputate ultricies mi, molestie sollicitudin sapien pulvinar et. Vestibulum volutpat sed nunc venenatis dapibus.</p>
            </div>
            <div className="price">
                ${props.flight.attributes.cost}.00 M.N. <span>Por persona</span>
            </div>
            <div className="pick-flight">
                <button onClick={() => pickFlight(props.flight,props.ticketNumber,props.ticketDate)} >Elegir vuelo!</button>
            </div>  
        </div>    
    </React.Fragment>
  );
};

export default CardFlight;
