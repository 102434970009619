import { ActionTypes } from "../constants/action-types";
const initialState = {
    tickets : []
}
export const ticketReducer = (state = initialState, {type, payload}) => {
    switch (type){
        case ActionTypes.ADD_TICKET:
            return {...state, tickets:[...state.tickets, payload]}
        case ActionTypes.DELETE_TICKET:
            return {...state, tickets: state.tickets.filter(item => item.id !== payload)}
        case ActionTypes.CLEAR_TICKETS:
            return {...state, tickets: []}
        default:
            return state;    
    }

}