import { ActionTypes } from "../constants/action-types";
const initialState = {
    cities : []
}
export const citiesReducer = (state=initialState, {type, payload}) => {
    switch (type){
        case ActionTypes.GET_CITIES:
            return {...state, cities:payload};
        default:
            return state;    
    }

}