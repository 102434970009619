import { ActionTypes } from '../constants/action-types';

export const setFlights = (flights) => {
     return{
         type: ActionTypes.SET_FLIGHTS,
         payload: flights
     }
}

export const ClearFlights = () => {
    return{
        type: ActionTypes.CLEAR_FLIGHT
    }
}