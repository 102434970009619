import { ActionTypes } from "../constants/action-types";
const initialState = {
    flights : []
}
export const flightsReducer = (state=initialState, {type, payload}) => {
    switch (type){
        case ActionTypes.SET_FLIGHTS:
            return {...state, flights:payload};
        case ActionTypes.CLEAR_FLIGHT:
            return {...state, flights: []}
        default:
            return state;    
    }

}