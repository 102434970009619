import React,{useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import Parse from 'parse/dist/parse.min.js';

import { setFlights } from '../redux/actions/flightActions';

import "react-datepicker/dist/react-datepicker.css";

import CardFlight from '../components/cardFlight/CardFlight';

const Home = props => {
  const store = useSelector(state => state);
  const dispatch = useDispatch();  
  const [origin,setOrigin] = useState(false);
  const [destination,setDestination] = useState(false);
  const [date, setDate] = useState(new Date());
  const [passengers, setPassengers] = useState(1);
  const [successMsg, setsuccessMsg] = useState(false);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,      
      fontWeight: state.isSelected ? 'bold' : 'normal',
      color: "#000",
      padding: 20,      
    }),
    indicatorsContainer: provided => ({
      ...provided,
      display: "none"
    }),
    valueContainer: provided => ({
      ...provided,
      color: "#FFF"
    }),
    menu: provided => ({
      ...provided,
      background: "rgba(255,255,255, 0.9)",
    }),
    control: provided => ({
      ...provided,
      height: 32,
      minHeight: 32,
      fontSize: 16,
      borderRadius: 0,
      width: "100%",
      textAlign: "left",
      cursor: "pointer",
      border: "none",
      background: "none",
      color: "#FFF"
    }),    
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color= "#FFF";
      return { ...provided, opacity, transition, color };
    }
  }
  

  const searchFlights = async () => {
    if(origin && destination && date && passengers){      
      const query = new Parse.Query('flights');      
      query.equalTo('city_origin', origin.value);
      query.equalTo('city_destination', destination.value);      
      const queryResult = await query.find();
      dispatch(setFlights(queryResult));
    }
  }

  const launchSuccessMsg = () => {
    setsuccessMsg(true);
    setTimeout(() => { setsuccessMsg(false); }, 2000)   
  }


  return (
    <React.Fragment>
      <div className="home">
        <div className="subtitle">
          <h2>Flights</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas malesuada ullamcorper sem, nec condimentum ligula varius a. Etiam vel ex sed turpis eleifend tempor a quis tellus. Proin ante dui, maximus at posuere eu, euismod viverra turpis.</p>
        </div>
        <div className="search-bar">
          <div className="search-input">          
            <label htmlFor="origin">Origen</label>
            <Select              
              options={store.allCities.cities.map(function (city) {
                return { value: city.attributes.name, label: city.attributes.name };
              })}            
              onChange={value=>{setOrigin(value);}}  
              styles={customStyles}
              placeholder={'De donde viajas...'}
              showIcon={false}
            />
            
          </div>          
          <div className="search-input">          
            <label htmlFor="destination">Destino</label>
            <Select              
              options={store.allCities.cities.map(function (city) {
                return { value: city.attributes.name, label: city.attributes.name };
              })}       
              onChange={value=>{setDestination(value);}} 
              styles={customStyles}
              placeholder={'Cual es tu destino?'}
              showIcon={false}    
            />
          </div>          
          <div className="search-input">          
            <label htmlFor="date">Fecha</label>
            <DatePicker selected={date} onChange={(newDate) => setDate(newDate)} />
          </div>          
          <div className="search-input">          
            <label htmlFor="passengers"># de personas</label>
            <select name="passengers" onChange={(event) => setPassengers(event.target.value)}  value={passengers}>            
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>            
              <option value="4">4</option>            
              <option value="5">5</option>            
              <option value="6">6</option>            
              <option value="7">7</option>            
              <option value="8">8</option>            
              <option value="9">9</option>            
              <option value="10">10</option>            
            </select>
          </div>
          <div className="search-action">
            <button onClick={searchFlights}>Buscar Boletos!</button>
          </div>          
        </div>
        
        <div className="search-results">
          {(store.flights.flights.length)?
          <div className="ok-results">
            <h2>¡Hemos encontrado {store.flights.flights.length} vuelos!</h2>
            <div className="list-results">
              {store.flights.flights.map((flight) =>
                <CardFlight key={flight.id} flight={flight} ticketNumber={passengers} ticketDate={date} successMsg={launchSuccessMsg}/>               
              )}
            </div>           
          </div>
          :null}
        </div>            
      </div>
      {(successMsg)?<div className="sucess-alert">¡El vuelo se ha agregado al carrito!</div>:null}
    </React.Fragment>    
  );
};

export default Home;
