import { ActionTypes } from '../constants/action-types';

export const AddTicket = (ticket) => {
     return{
         type: ActionTypes.ADD_TICKET,
         payload: ticket
     }
}

export const RemoveTicket = (ticket) => {
    return{
        type: ActionTypes.DELETE_TICKET,
        payload: ticket
    }
}

export const ClearTickets = () => {
    return{
        type: ActionTypes.CLEAR_TICKETS
    }
}